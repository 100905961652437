import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { LoginContext } from "../../Context/LoginContext/LoginContext";
import { Tooltip } from "react-tooltip";
import LazyLoadImages from "../../LazyLoadImages";

const { REACT_APP_S3_URL } = process.env;

interface MenuItem {
  name: string;
  path: string;
  imgSrc: string;
}

const menus: MenuItem[] = [
  // {
  //   name: "Home",
  //   path: "/dashboard",
  //   imgSrc: `${REACT_APP_S3_URL}/assets/icons/homewebp`,
  // },
  {
    name: "Projects",
    path: "/dashboard/campaigns",
    imgSrc: `${REACT_APP_S3_URL}/assets/icons/megaphone-white.webp`,
  },
  {
    name: "Statistics",
    path: "/dashboard/statistics",
    imgSrc: `${REACT_APP_S3_URL}/assets/icons/report.webp`,
  },
];

const CollapseSidebarMenu: React.FC = () => {
  const contextValue = useContext(LoginContext);
  console.log(contextValue?.loggedIn);
  const location = useLocation();

  return (
    <div className="mt-4 text-white">
      <ul className={`flex flex-col items-center text-base font-normal`}>
        {menus.map((menu, index) => (
          <li key={index}>
            <button className="py-1 hover:scale-105">
              <Link to={menu.path}>
                <div
                  className={`flex items-center rounded-md p-3 font-poppins font-normal opacity-75 hover:text-stone-100 hover:opacity-100 ${
                    location.pathname === menu.path ||
                    (menu.name === "Projects" &&
                      location.pathname.startsWith("/dashboard/campaigns"))
                      ? "scale-105 bg-[#282929ad] text-stone-100 opacity-100"
                      : ""
                  }`}
                >
                  <LazyLoadImages
                    data-tooltip-id={menu.name}
                    data-tooltip-content={menu.name}
                    src={menu.imgSrc}
                    className="h-6"
                    alt={menu.name}
                  />
                </div>
              </Link>
            </button>
            <Tooltip
              place="right"
              id={menu.name}
              className="custom-tooltip"
              noArrow
              style={{
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CollapseSidebarMenu;
