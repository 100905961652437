import React, { useEffect, useRef, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";

interface Plan {
  title: string;
  price: string;
  description: string;
  features: string[];
  buttonText: string;
  isPopular?: boolean;
  isCurrentPlan?: boolean;
  bestValue?: boolean;
}

const plans: Plan[] = [
  {
    title: "Basic",
    price: "$12 ",
    description: "Basic feature for single user",
    features: [
      "Free to Use",
      "Limited Functionality",
      "Upgrade Anytime",
      "2 more",
      "Free to Use",
      "Limited Functionality",
      "Upgrade Anytime",
    ],
    buttonText: "Get started",
    isCurrentPlan: true,
  },
  {
    title: "Premium",
    price: "$100 ",
    description: "Growing team up to 10 members",
    features: [
      "Free to Use",
      "Limited Functionality",
      "Upgrade Anytime",
      "5 more",
      "Free to Use",
      "Limited Functionality",
      "Upgrade Anytime",
    ],
    buttonText: "Get started",
    isPopular: true,
  },
  {
    title: "Business",
    price: "$200 ",
    description: "For unlimited users",
    features: [
      "Free to Use",
      "Limited Functionality",
      "Upgrade Anytime",
      "5 more",
      "Free to Use",
      "Limited Functionality",
      "Upgrade Anytime",
    ],
    buttonText: "Chat to sales",
    bestValue: true,
  },
];

const Plans: React.FC = () => {
  const [billingCycle, setBillingCycle] = useState<"monthly" | "annually">(
    "monthly",
  );
  const [hoveredPlan, setHoveredPlan] = useState<number | null>(null);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const planRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const heights = planRefs.current.map((ref) => ref?.offsetHeight || 0);
    const maxHeight = Math.max(...heights);
    setContainerHeight(maxHeight);
  }, [hoveredPlan]);

  return (
    <div className="px-8 py-2 lg:p-12">
      <div className="my-10 text-center lg:mt-0">
        <h1 className="text-2xl font-medium text-white lg:text-3xl">
          Find the{" "}
          <span className="text-[30px] font-bold text-[#3bde20] lg:text-[44px]">
            perfect plan
          </span>{" "}
          for you.
        </h1>
        <div className="mt-8 flex justify-center">
          <button
            className={`w-36 rounded-l-lg border py-2 text-[15px] transition-all duration-300 lg:text-base ${
              billingCycle === "monthly"
                ? "border-[#3bde20] bg-[#3dde2023] font-semibold text-[#3dde20d8]"
                : "border-stone-400 text-stone-200"
            }`}
            onClick={() => setBillingCycle("monthly")}
          >
            Monthly billed
          </button>
          <button
            className={`w-36 rounded-r-lg border py-2 text-[15px] transition-all duration-300 lg:text-base ${
              billingCycle === "annually"
                ? "border-[#3bde20] bg-[#3dde2023] font-semibold text-[#3dde20d8]"
                : "border-stone-400 text-stone-200"
            }`}
            onClick={() => setBillingCycle("annually")}
          >
            Annually billed
          </button>
        </div>
      </div>

      <div
        className="mt-2 flex flex-col items-center justify-center gap-4 space-y-6 lg:mt-16 lg:flex-row lg:gap-0 lg:space-x-8 lg:space-y-0"
        style={{ minHeight: `${containerHeight}px` }}
      >
        {plans.map((plan, index) => (
          <div
            ref={(el) => (planRefs.current[index] = el)}
            key={index}
            className={`relative w-full max-w-sm transform cursor-pointer rounded-xl p-6 shadow-lg transition-all ${
              (hoveredPlan === null && index === 1) || hoveredPlan === index
                ? "scale-105 border-2 border-[#3bde20] bg-[#3dde2023] text-stone-200 shadow-2xl"
                : "border border-stone-400 bg-[#1e1e1e]"
            }`}
            onMouseEnter={() => setHoveredPlan(index)}
            onMouseLeave={() => setHoveredPlan(null)}
          >
            {plan.isPopular && (
              <div className="absolute right-0 top-0 rounded-bl-lg rounded-tr-lg bg-[#3bde20] px-3 py-1 text-[15px] font-bold text-black">
                Recommended
              </div>
            )}
            <h2
              className={`mb-4 text-2xl font-bold tracking-wide ${
                (hoveredPlan === null && index === 1) || hoveredPlan === index
                  ? "text-[#3dde20d8]"
                  : "text-stone-200"
              }`}
            >
              {plan.title}
            </h2>
            <p
              className={`mb-2 text-2xl font-bold ${
                (hoveredPlan === null && index === 1) || hoveredPlan === index
                  ? "text-[#3dde20d8]"
                  : " text-stone-200"
              }`}
            >
              {plan.price}
              <span
                className={`text-lg font-medium ${
                  (hoveredPlan === null && index === 1) || hoveredPlan === index
                    ? "text-[#3dde20a2] text-opacity-80"
                    : "text-stone-300 text-opacity-80 "
                }`}
              >
                / month
              </span>
            </p>
            <p
              className={`mb-4 font-medium ${
                (hoveredPlan === null && index === 1) || hoveredPlan === index
                  ? "text-[#c1fdb4]"
                  : "text-stone-200"
              }`}
            >
              {plan.description}
            </p>
            <ul
              className={`mb-4 ${
                (hoveredPlan === null && index === 1) || hoveredPlan === index
                  ? "text-[#c1fdb4]"
                  : "text-stone-300"
              }`}
            >
              {plan.features.map((feature, i) => (
                <li key={i} className="mb-2 flex items-center">
                  <FaCheckCircle
                    className={`mr-2 ${
                      (hoveredPlan === null && index === 1) ||
                      hoveredPlan === index
                        ? "text-[#3dde20d8]"
                        : "text-stone-300 "
                    }`}
                  />{" "}
                  {feature}
                </li>
              ))}
            </ul>
            <button
              className={`my-2 w-full rounded-md py-2 font-medium transition-colors duration-300 ${
                (hoveredPlan === null && index === 1) || hoveredPlan === index
                  ? "bg-[#3dde208f] text-[#d9ffd0] hover:bg-opacity-80"
                  : "bg-stone-200 text-black hover:bg-opacity-80"
              }`}
            >
              {plan.buttonText}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plans;
