import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface LazyLoadImagesProps {
  src: string;
  alt: string;
  className?: string;
  onClick?: () => void; // Optional onClick handler
  style?: React.CSSProperties;
  title?: string;
}

const LazyLoadImages: React.FC<LazyLoadImagesProps> = ({
  src,
  alt,
  className,
  onClick,
  style,
  title,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick(); // Invoke onClick handler if provided
    }
  };

  const imageStyle: React.CSSProperties = {
    ...style, // Merge with provided style
  };

  console.log(className);
  return (
    <LazyLoadImage
      alt={alt}
      src={src}
      className={className}
      onClick={handleClick}
      style={imageStyle}
      title={title}
    />
  );
};

export default LazyLoadImages;
