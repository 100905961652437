import { useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";

const Faq = () => {
  const faqData = [
    {
      question: "What is the purpose of this website?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum.",
    },
    {
      question: "How can I create an account?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum.",
    },
    {
      question: "Where can I find the user manual?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum.",
    },
    {
      question: "What should I do if I forget my password?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum.",
    },
    {
      question: "How do I contact customer support?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum.",
    },
  ];

  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const toggleQuestion = (index: number) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div className="mb-24 mt-10 flex flex-col items-center justify-center gap-y-10 text-white">
      <div className="flex flex-col items-center justify-center gap-x-1  text-2xl xsm:flex-row  sm:gap-x-2.5 sm:text-3xl md:text-4xl ">
        <h1 className="font-medium">Frequently Asked</h1>
        <span className="text-[28px]   font-bold text-apple xsm:text-3xl md:text-heading">
          Questions
        </span>
      </div>

      <div className=" w-[90%]  max-w-2xl justify-start rounded-lg bg-dark-charcoal md:w-[70%]">
        {faqData.map((item, index) => (
          <div
            key={index}
            className={`${index !== faqData.length - 1 ? "border-b-[2px] border-[#ffffff2d]" : ""}`}
          >
            <div
              onClick={() => toggleQuestion(index)}
              className="flex min-h-[20px] cursor-pointer flex-row items-center justify-between px-5 font-semibold"
            >
              <span className="py-5 text-left text-lg font-medium">
                {item.question}
              </span>
              {expandedIndex === index ? (
                <ChevronUp size={20} />
              ) : (
                <ChevronDown size={20} />
              )}
            </div>
            {expandedIndex === index && (
              <div className="min-h-[20px] border-t border-[#ffffff2d] bg-white bg-opacity-10 px-5 py-5 text-left text-[17px] text-white text-opacity-80">
                {item.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
