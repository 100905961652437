import { useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import GoogleSSO from "../GoogleSSO";
import { ToastContainer } from "react-toastify";
import LazyLoadImages from "../LazyLoadImages";
import "react-toastify/dist/ReactToastify.css";

const { REACT_APP_S3_URL } = process.env;

interface SignUpProps {
  onEmailButtonClick: () => void;
}

const SignUp: React.FC<SignUpProps> = ({ onEmailButtonClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const campaignId = queryParams.get("gameId");
  const fromValue = queryParams.get("from");
  useEffect(() => {
    if (localStorage.getItem("xSudTzMYmd")) {
      navigate("/", { replace: true });
    }
  }, []);

  const handleLogoRedirect = () => {
    navigate("/");
  };

  return (
    <>
      <div className="h-screen items-center justify-center lg:flex">
        <div className="lg:h-screen lg:w-[50%] lg:bg-gray-100">
          <LazyLoadImages
            className="m-7 h-[25px] cursor-pointer xsm:h-6 xl:mx-10"
            src={`${REACT_APP_S3_URL}/assets/workplay-logo.webp`}
            alt="workplay-logo"
            onClick={handleLogoRedirect}
          />
          <div className="flex items-center justify-center lg:mt-20">
            <LazyLoadImages
              className="mt-6 h-[200px] xsm:h-[260px] sm:mt-0 sm:h-[320px] xl:h-[450px]"
              src={`${REACT_APP_S3_URL}/assets/sign-up.webp`}
              alt="sign-up"
            />
          </div>
        </div>
        <div className="m-auto flex items-center justify-center py-6 lg:w-[50%] lg:py-0">
          <div className="xxsm:w-[320px] w-[300px] xsm:w-[360px] sm:w-[400px] lg:w-[384px]">
            <h2 className="text-center text-2xl font-bold text-black lg:text-left lg:text-3xl">
              <strong className="relative before:bg-[#e9e7e7]">Create</strong>{" "}
              an account
            </h2>
            <p className="m-auto mb-6 mt-3 text-center text-[17px] font-medium sm:mt-4 lg:text-left">
              Get Started with Workplay!
            </p>
            <GoogleSSO />

            <span className="orline m-auto flex w-full items-center text-center">
              <p className="my-2 flex  whitespace-nowrap text-black">or</p>
            </span>

            <button
              className="xxsm:w-[320px] flex h-[44px] w-[300px] items-center justify-center rounded-md border border-solid border-black bg-black text-base font-medium text-white hover:scale-1.2 xsm:w-[360px] sm:w-[400px] lg:w-[384px]"
              onClick={onEmailButtonClick}
            >
              Continue with email
            </button>
            <p className="py-4 text-center text-base font-medium text-black">
              Already have an account?
              <Link
                to={
                  fromValue && campaignId
                    ? `/login?from=${fromValue}&gameId=${campaignId}`
                    : "/login"
                }
                className="ml-1 font-semibold text-black underline hover:opacity-60"
              >
                <strong className="font-semibol relative text-base before:bg-[#e9e7e7]">
                  Sign In
                </strong>
              </Link>
            </p>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default SignUp;
