import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import About from "./components/About";
import VisionMission from "./components/VisionMission";
import WhatWeDo from "./components/WhatWeDo";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import TrustedPartners from "./components/TrustedPartners";
import OurServices from "./components/OurServices";
import Game from "./components/Game";
import LoginContextProvider from "./components/Context/LoginContext/LoginContext";
import SignUpContainer from "./components/signUp/SignUpContainer";
import Login from "./components/login/Login";
import DashBoard from "./components/DashBoard/DashBoard";
import Settings from "./components/Settings/Settings";
import Campaigns from "./components/DashBoard/Campaigns/Campaigns";
import ForgotPassword from "./components/login/ForgotPassword";
import ResetPassword from "./components/login/ResetPassword";
import VerifyMail from "./components/VerifyMail";
import Profile from "./components/UserProfile/Profile";
import WorkInProgress from "./components/WorkInProgress";
import PageNotFound from "./components/PageNotFound";
import CampaignForm from "./components/DashBoard/Campaigns/CampaignForm";
import "./index.css";
import DeleteAccount from "./components/UserProfile/DeleteAccount";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Statistics from "./components/DashBoard/Statistics/Statistics";
import Integration from "./components/Integration";
import PlansAndFaqPage from "./pages/PlansAndFaqPage";
import ContactPage from "./pages/ContactPage";

function App() {
  return (
    <BrowserRouter>
      <LoginContextProvider>
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <Navbar />
                <Home />
                <About />
                <VisionMission />
                <WhatWeDo />
                <OurServices />
                <Integration />
                <TrustedPartners />
                <Footer />
              </Layout>
            }
          />
          <Route path="/profile" element={<Profile />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/game" element={<Game />} />
          <Route path="/plans" element={<PlansAndFaqPage />} />
          <Route path="/signup" element={<SignUpContainer />} />
          <Route path="/login" element={<Login />} />
          <Route path="/build" element={<WorkInProgress />} />
          <Route path="/profile/delete" element={<DeleteAccount />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/dashboard" element={<DashBoard />}>
            <Route path="gamedetails" element={<CampaignForm />} />
            <Route path="settings" element={<Settings />} />
            <Route path="statistics" element={<Statistics />} />
            <Route path="campaigns" element={<Campaigns />}>
              <Route path="create" element={<CampaignForm />} />
            </Route>
            <Route path="profile" element={<Profile />} />
          </Route>
          <Route path="/forgetpassword" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/account-verify" element={<VerifyMail />} />
        </Routes>
      </LoginContextProvider>
    </BrowserRouter>
  );
}

export default App;
