import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, Link } from "react-router-dom";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { LoginContext } from "../Context/LoginContext/LoginContext";
import GoogleSSO from "../GoogleSSO";
import LazyLoadImages from "../LazyLoadImages";

const {
  REACT_APP_S3_URL,
  REACT_APP_CUSTOM_SPIN_URL,
  REACT_APP_CUSTOM_QUIZ_URL,
  REACT_APP_API_URL,
} = process.env;

const Login: React.FC = () => {
  const navigate = useNavigate();
  const contextValue = useContext(LoginContext);
  const urlParams = new URLSearchParams(window.location.search);
  const fromValue = urlParams.get("from");
  const campaignId = urlParams.get("gameId");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const storedCredentialsString = Cookies.get("xSudTzMYmdCred");
    try {
      if (storedCredentialsString) {
        const storedCredentials = JSON.parse(storedCredentialsString);
        setEmail(storedCredentials.email);
        setPassword(storedCredentials.password);
      }
    } catch (error) {
      console.error("Error parsing stored credentials:", error);
    }
  }, []);

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleLogoRedirect = () => {
    navigate("/");
  };

  const redirectToGame = (token: any) => {
    if (campaignId && fromValue) {
      navigate(
        `/dashboard/campaigns/create?gameId=${encodeURIComponent(campaignId)}&fromValue=${fromValue}`,
      );
    }
  };

  const handleSubmit = async () => {
    if (!email && !password) {
      toast.error("Please fill in all fields", {
        autoClose: 2000,
        className: "toastify-toast",
      });
      return;
    }

    let isEmailValid = true;
    let isPasswordValid = true;

    if (!validateEmail(email)) {
      toast.error("Invalid email address", {
        autoClose: 2000,
        className: "toastify-toast",
      });
      isEmailValid = false;
    }

    if (!password) {
      toast.error("Password is required", {
        autoClose: 2000,
        className: "toastify-toast",
      });
      isPasswordValid = false;
    }

    if (isEmailValid && isPasswordValid) {
      const URL = `${REACT_APP_API_URL}/auth/login`;
      if (!URL) {
        return;
      }
      try {
        const response = await axios.post(
          URL,
          {
            email: email,
            password: password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );

        // Redirect after creating user
        if (response.data.success === true) {
          contextValue?.login();
          localStorage.setItem("xSudTzMYmd", response.data.data.accessToken);
          if (rememberMe) {
            const credentials = { email, password };
            const credentialsString = JSON.stringify(credentials);
            Cookies.set("xSudTzMYmdCred", credentialsString, { expires: 30 });
          } else {
            Cookies.remove("xSudTzMYmdCred");
          }
          if (campaignId && fromValue) {
            redirectToGame(response.data.data.accessToken);
          } else {
            setTimeout(() => {
              navigate("/dashboard/campaigns", { replace: true });
            }, 1000);
          }
          contextValue?.login();
        }
        setPassword("");
        setEmail("");
        console.log("Form submitted!");
        console.log(response);
      } catch (error: any) {
        setPassword("");
        setEmail("");
        toast.error(error.response.data.message, {
          autoClose: 2000,
          className: "toastify-toast",
        });
        console.error("Error creating user:", error);
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("xSudTzMYmd")) {
      const token = localStorage.getItem("xSudTzMYmd");
      if (fromValue && campaignId) {
        redirectToGame(token);
      } else navigate("/", { replace: true });
    }
  }, []);

  return (
    <>
      <div
        style={{
          pointerEvents: localStorage.getItem("xSudTzMYmd") ? "none" : "auto",
        }}
      >
        <div className="min-h-screen items-center lg:flex">
          <div className="lg:h-screen lg:w-[50%] lg:bg-gray-100">
            <LazyLoadImages
              className="m-7 h-[25px] cursor-pointer xsm:h-6 xl:mx-10"
              src={`${REACT_APP_S3_URL}/assets/workplay-logo.webp`}
              alt="workplay-logo"
              onClick={handleLogoRedirect}
            />
            <div className="flex items-center justify-center lg:mt-20">
              <LazyLoadImages
                className="h-[200px]  xsm:h-[260px] sm:h-[320px] xl:h-[450px]"
                src={`${REACT_APP_S3_URL}/assets/login.webp`}
                alt="login"
              />
            </div>
          </div>
          <div className="m-auto flex items-center justify-center py-6 lg:w-[50%] lg:py-0">
            <div className="xxsm:w-[320px] w-[300px] xsm:w-[360px] sm:w-[400px] lg:w-[384px]">
              <div>
                <h2 className="text-center text-2xl font-bold text-black lg:text-left lg:text-3xl">
                  <strong className="relative before:bg-[#dfe9f8]">
                    Welcome
                  </strong>{" "}
                  back
                </h2>
              </div>
              <p className="m-auto mb-6 mt-3 text-center text-[17px] font-medium sm:mt-4 lg:text-left">
                Ready to dive back in? Log in now!
              </p>
              <div className="m-auto flex flex-col items-center justify-center gap-y-4">
                <div className="flex flex-col">
                  <div className="flex flex-col text-base text-black">
                    <div className="flex text-left">
                      <label className="text-base font-medium">Email</label>
                      <p className="font-semibold text-red-600">&nbsp;*</p>
                    </div>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      className="xxsm:w-[320px] m-auto my-2 h-[44px] w-[300px] rounded-md bg-white px-3 text-base text-black xsm:w-[360px] sm:w-[400px] lg:w-[384px]"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />

                    <div className="flex text-left">
                      <label
                        className="mb-2 text-base font-medium"
                        htmlFor="password"
                      >
                        Password
                      </label>
                      <p className="font-semibold text-red-600">&nbsp;*</p>
                    </div>
                    <div className="relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        value={password}
                        className="xxsm:w-[320px] h-[44px] w-[300px] rounded-md bg-white px-3 text-black xsm:w-[360px] sm:w-[400px] lg:w-[384px]"
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter your password"
                        required
                      />
                      <FontAwesomeIcon
                        icon={showPassword ? faEyeSlash : faEye}
                        className="absolute right-4 top-1/2 h-4 -translate-y-1/2 transform cursor-pointer text-black"
                        onClick={togglePasswordVisibility}
                      />
                    </div>
                  </div>
                  <div className="mt-2 flex flex-row items-center justify-between">
                    <label className="flex items-center font-normal">
                      <input
                        type="checkbox"
                        checked={rememberMe}
                        onChange={handleRememberMe}
                        className="mr-2 h-4 w-4 cursor-pointer border-2 border-black"
                      />
                      <p className="text-base font-medium sm:text-[15px]">
                        Remember me
                      </p>
                    </label>
                    <Link
                      to="/forgetpassword"
                      className="cursor-pointer text-base font-semibold text-black underline hover:opacity-60 sm:text-[15px]"
                    >
                      Forgot password?
                    </Link>
                  </div>
                </div>

                <button
                  className="xxsm:w-[320px] flex h-[44px] w-[300px] items-center justify-center rounded-md border border-solid border-black bg-black text-base font-medium text-white hover:scale-1.2 xsm:w-[360px] sm:w-[400px] lg:w-[384px]"
                  onClick={handleSubmit}
                >
                  Login
                </button>
                <>
                  <span className="orline m-auto flex w-full items-center text-center">
                    <p className="-my-2 flex  whitespace-nowrap text-black">
                      or
                    </p>
                  </span>
                  <GoogleSSO />
                </>
              </div>

              <p className="mt-4 text-center font-medium text-black">
                Don't have an account?
                <Link
                  to={
                    fromValue && campaignId
                      ? `/signup?from=${fromValue}&gameId=${campaignId}`
                      : "/signup"
                  }
                  className="ml-1 text-black underline hover:opacity-60"
                >
                  <strong className="relative text-base font-semibold before:bg-[#dfe9f8]">
                    Sign up
                  </strong>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Login;
