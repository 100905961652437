import React from "react";
import LazyLoadImages from "./LazyLoadImages";
import "../styles/Sections/TrustedPartners.css";

interface Partner {
  name: string;
  logo: string;
  className?: string;
}

const { REACT_APP_S3_URL } = process.env;

const partnerData: Partner[] = [
  {
    name: "Microsoft",
    logo: `${REACT_APP_S3_URL}/assets/microsoft-logo.webp`,
  },
  {
    name: "Lenovo",
    logo: `${REACT_APP_S3_URL}/assets/lenovo-logo.webp`,
  },
  {
    name: "Optimum",
    logo: `${REACT_APP_S3_URL}/assets/optimum-logo.webp`,
  },
  {
    name: "Tata Tea",
    logo: `${REACT_APP_S3_URL}/assets/tata-tea-logo.webp`,
  },
  {
    name: "Three Wheels United",
    logo: `${REACT_APP_S3_URL}/assets/three-wheels-logo.webp`,
  },
  {
    name: "Dharma Endevours",
    logo: `${REACT_APP_S3_URL}/assets/dharma-logo.webp`,
    className: "dharma",
  },
  {
    name: "Progressive Foundation",
    logo: `${REACT_APP_S3_URL}/assets/progressive-foundation-logo.webp`,
    className: "progressive-foundation",
  },
  {
    name: "PI India Org",
    logo: `${REACT_APP_S3_URL}/assets/pi-india-org-logo.webp`,
  },
  {
    name: "Jute & co.",
    logo: `${REACT_APP_S3_URL}/assets/jute-logo.webp`,
    className: "jute",
  },
  {
    name: "Wiitronics",
    logo: `${REACT_APP_S3_URL}/assets/wiitronics-logo.webp`,
    className: "wiitronics",
  },
  {
    name: "Vilz",
    logo: `${REACT_APP_S3_URL}/assets/vilz-logo.webp`,
  },
  {
    name: "Thar Tech",
    logo: `${REACT_APP_S3_URL}/assets/thar-tech-logo.webp`,
  },
  {
    name: "Phoenix Live",
    logo: `${REACT_APP_S3_URL}/assets/phoenix-live.webp`,
  },
];

const TrustedPartners: React.FC = () => {
  return (
    <section
      id="trusted-partners"
      className="bg-black px-8 pb-8 pt-9 text-center font-bold text-white sm:px-10 sm:py-16 lg:p-16 xl:px-32 xl:py-16 3xl:py-28"
    >
      <h1 className="title pb-3 text-[1.6rem] text-white xsm:text-[2rem] sm:pb-6 sm:text-4xl xl:pb-8 xl:text-heading">
        Trusted by Leading Companies
      </h1>
      <div className="m-auto mb-3 mt-6 flex flex-wrap justify-center sm:mt-12 3xl:max-w-[75rem]">
        {partnerData.flat().map((partner, index) => (
          <LazyLoadImages
            key={index}
            src={partner.logo}
            alt={partner.name}
            className={partner.className}
          />
        ))}
      </div>
    </section>
  );
};

export default TrustedPartners;
