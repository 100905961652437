import React from "react";
import "../styles/Sections/AboutUs.css";
import LazyLoadImages from "./LazyLoadImages";

const { REACT_APP_S3_URL } = process.env;

interface AboutData {
  title: string;
  mainText: string;
  children: {
    text: string;
    imageSrc: string;
  }[];
}

const aboutData: AboutData = {
  title: "About Us",
  mainText:
    "WorkPlay creates innovative gaming concepts that create a digital bonding between consumers and brands. This bonding leads to appreciating the true values the brand stands for and leading to purchase and become lifetime consumers.",
  children: [
    {
      text: "We build experiences that connect on a human “difference-making” level to make a true impression discovering compelling insights into human nature, and the real client value begins from here.",
      imageSrc: `${REACT_APP_S3_URL}/assets/people-collaborating-with-tech.webp`,
    },
    {
      text: "Our unique gamified solutions will increase the engagement and provide you with more data points to analyze, increasing your conversion rate and making your campaign optimization faster.",
      imageSrc: `${REACT_APP_S3_URL}/assets/gamify-solutions.webp`,
    },
  ],
};

const About: React.FC = () => {
  return (
    <section
      id="about"
      className="bg-[#3bde20] p-8 pb-9 pt-8 text-center font-bold text-[#292929] sm:px-10 sm:py-16 lg:p-16 xl:px-32 xl:py-12 3xl:py-28"
    >
      <div className="about-content">
        <h1 className="mb-6 pb-3 text-[2rem] text-white xsm:pb-6 xsm:text-[2.2rem] sm:mb-10 sm:text-4xl xl:text-heading">
          {aboutData.title}
        </h1>
        <p className="m-auto mb-7 w-full text-base sm:max-w-4xl md:text-lg lg:mb-10">
          {aboutData.mainText}
        </p>
        <LazyLoadImages
          className="m-auto h-[160px] min-[340px]:h-[210px] min-[480px]:h-[250px] 2xl:h-[360px]"
          src={`${REACT_APP_S3_URL}/assets/people-working-together.webp`}
          alt="people working together"
        />
      </div>
      <div className="about-container">
        {aboutData.children.map((child, index) => (
          <div
            key={index}
            className={`child${index + 1} m-auto flex w-full items-center justify-evenly xl:mb-5`}
          >
            {index % 2 === 0 ? (
              <>
                <p className="m-auto my-6 w-full  text-base max-[500px]:w-full sm:max-w-4xl lg:max-w-[350px] xl:max-w-[500px] xl:text-lg">
                  {child.text}
                </p>
                <LazyLoadImages
                  className="m-auto h-[160px] min-[360px]:h-[180px] min-[480px]:h-[210px] 2xl:h-[300px]"
                  src={child.imageSrc}
                  alt={`${index + 1}`}
                />
              </>
            ) : (
              <>
                <LazyLoadImages
                  className="m-auto h-[160px] min-[340px]:h-[200px] min-[480px]:h-[260px] 2xl:h-[300px]"
                  src={child.imageSrc}
                  alt={`${index + 1}`}
                />
                <p className="m-auto my-6 w-full text-base max-[500px]:w-full sm:max-w-4xl lg:max-w-[350px] xl:max-w-[500px] xl:text-lg">
                  {child.text}
                </p>
              </>
            )}
          </div>
        ))}
      </div>
      <LazyLoadImages
        className="child2-image m-auto mb-3 h-[180px] xsm:h-[200px] min-[480px]:h-[210px] xl:h-[400px]"
        src={`${REACT_APP_S3_URL}/assets/gamify-solutions.webp`}
        alt="people working together"
      />
    </section>
  );
};

export default About;
