import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { LoginContext } from "././Context/LoginContext/LoginContext";
import LazyLoadImages from "./LazyLoadImages";

import axios from "axios";

const {
  REACT_APP_API_URL,
  REACT_APP_S3_URL,
  REACT_APP_CUSTOM_SPIN_URL,
  REACT_APP_CUSTOM_QUIZ_URL,
} = process.env;

interface GoogleSSOProps {}

const GoogleSSO: React.FC<GoogleSSOProps> = ({}) => {
  const navigate = useNavigate();
  const contextValue = useContext(LoginContext);
  const urlParams = new URLSearchParams(window.location.search);
  const fromValue = urlParams.get("from");
  const campaignId = urlParams.get("gameId");

  const redirectToGame = (token: any) => {
    if (campaignId && fromValue) {
      navigate(
        `/dashboard/campaigns/create?gameId=${encodeURIComponent(campaignId)}&fromValue=${fromValue}`,
      );
    }
  };

  const handleLoginSuccess = async (codeResponse: any) => {
    const code = codeResponse.code;
    try {
      const response = await axios.post(`${REACT_APP_API_URL}/auth/sso`, {
        code,
      });
      localStorage.setItem("xSudTzMYmd", response.data.data.accessToken);

      contextValue?.login();
      if (campaignId && fromValue) {
        redirectToGame(response.data.data.accessToken);
      } else {
        setTimeout(() => {
          navigate("/dashboard/campaigns", { replace: true });
        }, 1000);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: handleLoginSuccess,
    flow: "auth-code",
  });

  return (
    <>
      <button
        onClick={() => googleLogin()}
        className="xxsm:w-[320px] flex h-[44px] w-[300px] items-center justify-center rounded-md border border-solid border-black bg-white text-base font-medium text-black hover:scale-1.2 xsm:w-[360px] sm:w-[400px] sm:border-2 sm:border-[#b3b3b3] lg:w-[384px]"
      >
        <LazyLoadImages
          className="mr-3 h-5"
          src={`${REACT_APP_S3_URL}/assets/google.webp`}
          alt="google"
        />
        Continue with Google
      </button>
    </>
  );
};

export default GoogleSSO;
