import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginContext } from "../../Context/LoginContext/LoginContext";
import { userContext } from "../../Context/LoginContext/UserContext";
import LazyLoadImages from "../../LazyLoadImages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faEdit,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Tooltip } from "react-tooltip";
import path from "path";

const { REACT_APP_S3_URL, REACT_APP_API_URL } = process.env;

const CollapseSideBarFooter: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [profile, setProfile] = useState(false);
  const { user, setUser } = useContext(userContext);
  const contextValue = useContext(LoginContext);
  const additionalMenuItems = [
    {
      name: "Logout",
      path: "/",
      icon: faSignOutAlt,
    },
  ];

  const handleSetUser = (data: any) => {
    setUser({
      email: data.email,
      fname: data.firstName,
      lname: data.lastName,
      phone: data.phoneNumber,
      isdcode: data.isdCode,
      img: {
        source: data.img.source,
        link:
          data.img.source === "bucket"
            ? `${REACT_APP_S3_URL}/uploads/profile/${data.img.link}`
            : data.img.link,
      },
      bio: data.bio,
      location: data.location,
      socials: data.socials,
      company: data.company,
    });
  };

  useEffect(() => {
    const token = localStorage.getItem("xSudTzMYmd");
    const fetchProfile = async () => {
      const URL = `${REACT_APP_API_URL}/auth/profile`;
      if (!URL) {
        return;
      }
      try {
        const response = await axios.get(URL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success === true) {
          contextValue?.login();
          handleSetUser(response.data.data);
          if (response.data.data.img.source === "oauth") {
            sessionStorage.setItem("ImgLink", response.data.data.img.link);
            sessionStorage.setItem("fname", response.data.data.firstName);
            sessionStorage.setItem("lname", response.data.data.lastName);
            sessionStorage.setItem("email", response.data.data.email);
          }

          if (response.data.data.img.source === "bucket") {
            sessionStorage.setItem(
              "ImgLink",
              `${REACT_APP_S3_URL}/uploads/profile/${response.data.data.img.link}`,
            );
            sessionStorage.setItem("fname", response.data.data.firstName);
            sessionStorage.setItem("lname", response.data.data.lastName);
            sessionStorage.setItem("email", response.data.data.email);
          }
        }
      } catch (error: any) {
        contextValue?.logout();
        localStorage.removeItem("xSudTzMYmd");
        sessionStorage.removeItem("ImgLink");
        sessionStorage.removeItem("lname");
        sessionStorage.removeItem("fname");
        sessionStorage.removeItem("email");
        console.log(error);
      }
    };
    if (token) {
      fetchProfile();
    }
  }, []);

  const handleProfileNavigate = () => {
    navigate("profile");
    setProfile(false);
  };

  return (
    <div className="">
      <div>
        <LazyLoadImages
          src={
            sessionStorage.getItem("ImgLink") ||
            user.img.link ||
            `${REACT_APP_S3_URL}/uploads/profile/default-profile.webp`
          }
          data-tooltip-id="profile"
          data-tooltip-content="Profile"
          className="h-10 w-10 cursor-pointer rounded-full"
          alt="profile"
          onClick={() => setProfile(!profile)}
        />
        <Tooltip
          id="profile"
          className="custom-tooltip"
          noArrow
          place="right"
          style={{ fontSize: "14px", fontFamily: "Poppins" }}
        />
        <ul className="mt-8 flex items-center justify-center text-sm text-[#636363]">
          {additionalMenuItems.map((item, index) => (
            <li>
              <button
                key={index}
                onClick={() => {
                  if (item.name === "Logout") {
                    contextValue?.logout();
                    localStorage.clear();
                    sessionStorage.clear();
                    sessionStorage.removeItem("ImgLink");
                    sessionStorage.removeItem("fname");
                    sessionStorage.removeItem("lname");
                    sessionStorage.removeItem("email");
                    sessionStorage.removeItem("collapse");
                    navigate(item.path);
                  } else {
                    window.location.href = `${item.path}`;
                  }
                }}
                className="font-poppins opacity-75 hover:scale-105 hover:opacity-100"
              >
                <LazyLoadImages
                  data-tooltip-id="logout"
                  data-tooltip-content="Logout"
                  className="h-6"
                  src={`${REACT_APP_S3_URL}/assets/icons/logout.webp`}
                  alt="logout"
                />
              </button>
              <Tooltip
                id="logout"
                className="custom-tooltip"
                noArrow
                place="right"
                style={{ fontSize: "14px", fontFamily: "Poppins" }}
              />
            </li>
          ))}
        </ul>
      </div>
      {profile && (
        <div className="absolute bottom-12 left-[80px] flex w-[280px] flex-col rounded-lg bg-[#1d1c1c] p-3">
          <div className="flex items-center pb-1 text-stone-100">
            <LazyLoadImages
              src={
                sessionStorage.getItem("ImgLink") ||
                user.img.link ||
                `${REACT_APP_S3_URL}/uploads/profile/default-profile.webp`
              }
              className="mr-3 h-10 rounded-full"
              alt="profile"
            />
            {sessionStorage.getItem("fname") &&
              sessionStorage.getItem("lname") &&
              sessionStorage.getItem("email") && (
                <div>
                  <p className="text-sm">
                    {sessionStorage.getItem("fname") +
                      " " +
                      sessionStorage.getItem("lname")}
                  </p>
                  <p className="text-[12px] opacity-75">
                    {sessionStorage.getItem("email")}
                  </p>
                </div>
              )}
          </div>
          {location.pathname !== "/dashboard/profile" && (
            <div>
              <div className="my-2 w-auto border-b border-[#aaaaaa]"></div>
              <button
                className="absolute right-4 top-2"
                onClick={() => setProfile(!profile)}
              >
                <FontAwesomeIcon
                  className="h-[18px] text-stone-100"
                  icon={faClose}
                />
              </button>
              <div className="flex cursor-pointer items-center p-2 hover:scale-95 hover:opacity-60">
                <FontAwesomeIcon className="h-4 text-stone-100" icon={faEdit} />
                <p
                  className="pl-3 font-poppins text-sm text-stone-100"
                  onClick={handleProfileNavigate}
                >
                  Edit Profile
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CollapseSideBarFooter;
