import LazyLoadImages from "../LazyLoadImages";
const { REACT_APP_S3_URL } = process.env;

const DashBoardHome = () => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="m-auto w-full">
        <p className="my-1 text-center font-poppins text-xl font-normal tracking-wide text-black lg:text-2xl">
          Welcome to WorkPlay!
        </p>
        <p className="text-center font-poppins text-[15px] font-light tracking-wide text-black lg:text-base">
          Let's level up your game together!
        </p>
        <LazyLoadImages
          src={`${REACT_APP_S3_URL}/assets/rocket-ignitewebp`}
          className="m-auto mt-8 h-72 text-center sm:h-80 lg:h-[380px]"
          alt="campaign"
        />
      </div>
    </div>
  );
};

export default DashBoardHome;
