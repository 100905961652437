import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LazyLoadImages from "../LazyLoadImages";
import "react-toastify/dist/ReactToastify.css";

const { REACT_APP_S3_URL, REACT_APP_API_URL } = process.env;

interface ResetPasswordProps {}

const ResetPassword: React.FC<ResetPasswordProps> = ({}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const URL = `${REACT_APP_API_URL}/auth/reset-password`;
  const code = searchParams.get("code");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  const handlePasswordChange = (password: string) => {
    // Set form data
    setFormData({
      ...formData,
      password: password,
    });
  };

  const handleConfirmPasswordChange = (confirmPassword: string) => {
    setFormData({
      ...formData,
      confirmPassword: confirmPassword,
    });
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();

    // Validation regular expressions
    const digitRegex = /\d/;
    const uppercaseRegex = /[A-Z]/;
    const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

    if (
      formData.password !== formData.confirmPassword &&
      formData.password &&
      formData.confirmPassword
    ) {
      toast.error("Passwords do not match.", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    } else if (!formData.password && !formData.confirmPassword) {
      toast.error("Please fill all the fields.", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    } else if (!formData.password) {
      toast.error("Please enter your new password, then confirm it.", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    } else if (!formData.confirmPassword) {
      toast.error("Confirm Password.", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    } else if (formData.password.length < 8) {
      toast.error("Password must be at least 8 characters long.", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    } else if (!digitRegex.test(formData.password)) {
      toast.error("Password must contain at least one digit.", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    } else if (!uppercaseRegex.test(formData.password)) {
      toast.error("Password must contain at least one uppercase letter.", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    } else if (!specialCharRegex.test(formData.password)) {
      toast.error("Password must contain at least one special character.", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    } else {
      try {
        if (!URL) {
          return;
        }
        const response = await axios.patch(
          `${URL}/${code}`,
          {
            password: formData.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );
        if (response.data.success === true) {
          setFormData({
            password: "",
            confirmPassword: "",
          });
          toast.success("Password reset successful!", {
            autoClose: 3000,
            className: "toastify-toast",
          });
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        }
      } catch (error: any) {
        toast.error(error.response.data.message);
        console.error("Error creating user:", error);
        setFormData({
          password: "",
          confirmPassword: "",
        });
      }
    }
  };

  const handleResetPassWord = async () => {
    if (!URL) {
      return;
    }
    try {
      const Response = await axios.get(`${URL}/${code}`);
      if (Response.data.success === true) {
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
      console.log(error);
    }
  };

  useEffect(() => {
    handleResetPassWord();
  }, []);

  const handleLogoRedirect = () => {
    navigate("/");
  };

  return (
    <>
      <div className="min-h-screen items-center lg:flex">
        <div className="lg:h-screen lg:w-[50%] lg:bg-gray-100">
          <LazyLoadImages
            className="m-7 h-[25px] cursor-pointer xsm:h-6 xl:mx-10"
            src={`${REACT_APP_S3_URL}/assets/workplay-logo.webp`}
            alt="workplay-logo"
            onClick={handleLogoRedirect}
          />
          <div className="flex items-center justify-center lg:mt-20">
            <LazyLoadImages
              className="h-[200px] xsm:h-[260px] sm:h-[320px] xl:h-[450px]"
              src={`${REACT_APP_S3_URL}/assets/reset.webp`}
              alt="reset-pass"
            />
          </div>
        </div>
        <div className="m-auto flex items-center justify-center py-6 lg:w-[50%] lg:py-0">
          <div className="xxsm:w-[320px] w-[300px] xsm:w-[360px] sm:w-[400px] lg:w-[384px]">
            <h1 className="text-center text-2xl font-bold text-black lg:text-left lg:text-3xl">
              <strong className="relative before:bg-[#faded7]">Create</strong>{" "}
              new password
            </h1>
            <p className="m-auto mb-6 mt-3 text-center text-[17px] font-medium sm:mt-4 lg:text-left">
              Please enter your new password below.
            </p>
            <div className="relative flex flex-col text-black">
              <div className="flex text-left">
                <label className="text-base font-medium">New Password</label>
                <p className="font-semibold text-red-600">&nbsp;*</p>
              </div>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                className="xxsm:w-[320px] m-auto my-2 h-[44px] w-[300px] rounded-md bg-white px-3 text-base text-black xsm:w-[360px] sm:w-[400px] lg:w-[384px]"
                placeholder="Enter your password"
                value={formData.password}
                onChange={(e) => {
                  handlePasswordChange(e.target.value);
                }}
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="absolute right-4 top-[54px] h-4 -translate-y-1/2 transform cursor-pointer text-black md:top-[55px] md:w-6"
                onClick={togglePasswordVisibility}
              />
            </div>
            <div className="relative flex flex-col text-black">
              <div className="mb-2 flex text-left ">
                <label className="font-medium">Confirm Password</label>
                <p className="font-semibold text-red-600">&nbsp;*</p>
              </div>
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                className="xxsm:w-[320px] h-[44px] w-[300px] rounded-md bg-white px-3 text-black xsm:w-[360px] sm:w-[400px] lg:w-[384px]"
                placeholder="Confirm your password"
                value={formData.confirmPassword}
                onChange={(e) => {
                  handleConfirmPasswordChange(e.target.value);
                }}
              />
              <FontAwesomeIcon
                icon={showConfirmPassword ? faEyeSlash : faEye}
                className="absolute bottom-[6px] right-4 h-4 -translate-y-1/2 transform cursor-pointer text-black sm:right-[18px]"
                onClick={toggleConfirmPasswordVisibility}
              />
            </div>
            <button
              type="submit"
              onClick={handleSubmit}
              className="xxsm:w-[320px] mt-4 flex h-[44px] w-[300px] items-center justify-center rounded-md border border-solid border-black bg-black text-base font-medium text-white hover:scale-1.2 xsm:w-[360px] sm:w-[400px] lg:w-[384px]"
            >
              Reset password
            </button>
            <p className="mt-4 text-center text-base font-medium text-black">
              Did you just remember your password?
              <div className="hidden xsm:inline">
                <a
                  href="/login"
                  className="ml-1.5 font-semibold text-black underline hover:opacity-60"
                >
                  <strong className="relative text-base font-semibold before:bg-[#faded7]">
                    Sign In
                  </strong>
                </a>
              </div>
            </p>
            <div className="m-auto flex justify-center text-center xsm:hidden">
              <a
                href="/login"
                className="ml-1 font-semibold text-black underline hover:opacity-60"
              >
                <strong className="relative text-base font-semibold before:bg-[#faded7]">
                  Sign In
                </strong>
              </a>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ResetPassword;
