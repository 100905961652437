import axios from "axios";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LazyLoadImages from "../LazyLoadImages";
import "react-toastify/dist/ReactToastify.css";
import { persistor } from "../../app/store";

const { REACT_APP_S3_URL, REACT_APP_API_URL } = process.env;

const DeleteAccount = () => {
  const navigate = useNavigate();
  const [showCodeField, setShowCodeField] = useState(false);
  const [inputs, setInputs] = useState<string[]>(Array(6).fill(""));
  const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(6).fill(null));

  const handleChange = (value: string, index: number) => {
    const newInputs = [...inputs];
    newInputs[index] = value.slice(0, 1); // Ensures only the first character is taken, in case of pasted input
    setInputs(newInputs);

    // Move focus to next input if value exists and isn't the last input
    if (value && index < 5 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === "Backspace" && index > 0 && !inputs[index]) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (
    index: number,
    event: React.ClipboardEvent<HTMLInputElement>,
  ) => {
    const clipboardData = event.clipboardData.getData("text");
    const pastedCode = clipboardData.match(/\d{1,6}/)?.[0] || ""; // Extract the first 6-digit code from the pasted content
    if (pastedCode.length === 6) {
      const newInputs = [...inputs];
      for (let i = 0; i < 6; i++) {
        if (index + i < 6) {
          newInputs[index + i] = pastedCode[i];
        }
      }
      setInputs(newInputs);
    }
    event.preventDefault(); // Prevent default paste behavior to avoid inserting invalid characters
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.key;
    if (!/^\d$/.test(key)) {
      event.preventDefault(); // Prevent default behavior if key pressed is not a number
    }
  };

  const handleAccountDelete = async () => {
    const token = localStorage.getItem("xSudTzMYmd");
    const URL = `${REACT_APP_API_URL}/auth/request-account-deletion`;

    if (!token || !URL) {
      toast.error("Authentication token or URL is missing.");
      return;
    }

    try {
      const response = await axios.post(
        URL,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.status === 200) {
        toast.success("Please check your inbox for account deletion code.", {
          autoClose: 2000,
          className: "toastify-toast",
        });
        setShowCodeField(true);
      } else {
        toast.error("Oops! Something went wrong! Please try again later.", {
          autoClose: 2000,
          className: "toastify-toast",
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Oops! Something went wrong! Please try again later.", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    }
  };

  const handleConfirmDelete = async () => {
    if (inputs.some((input) => input === "")) {
      toast.error("Please enter the 6-digit code.", {
        autoClose: 1000,
        className: "toastify-toast",
      });
      return;
    }

    const code = inputs.join("");
    const URL = `${REACT_APP_API_URL}/auth/delete-account`;
    const token = localStorage.getItem("xSudTzMYmd");
    if (!URL || !token) {
      toast.error("Authentication token or URL is missing.");
      return;
    }

    try {
      const response = await axios.post(
        URL,
        { code: code },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.status === 200) {
        toast.success("Your account has been deleted successfully!", {
          autoClose: 1000,
          className: "toastify-toast",
        });
        localStorage.clear();
        sessionStorage.clear();
        persistor.purge().then(() => {
          setTimeout(() => {
            navigate("/");
          }, 1500);
        });
      } else {
        toast.error(
          "Oops! Something went wrong! Failed to delete account. Please try again later.",
          {
            autoClose: 2000,
            className: "toastify-toast",
          },
        );
      }
    } catch (error) {
      console.log(error);
      toast.error("Oops! Something went wrong! Please try again later.", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    }
  };

  return (
    <section className="h-screen w-full">
      <div className="m-auto flex h-screen items-center justify-center px-4 lg:w-[50%] lg:px-8">
        <div className="h-fit rounded-2xl border border-stone-100 px-8 pb-7 text-center shadow-sm drop-shadow lg:px-16 lg:pb-10 lg:pt-5">
          <LazyLoadImages
            src={`${REACT_APP_S3_URL}/assets/sad-women.webp`}
            alt="delete-account"
            className="m-auto h-40 text-center lg:h-60"
          />
          <h1 className="my-5 text-lg font-semibold lg:text-2xl">
            We're sorry to see you go
          </h1>
          {!showCodeField ? (
            <>
              <p className="m-auto lg:max-w-80">
                Please be aware that deleting your account is irreversible. Once
                deleted, your account cannot be restored. If you choose to
                proceed, we will send you a six-digit code to confirm and
                finalize the deletion of your account.
              </p>{" "}
              <div className="mt-5 flex items-center justify-center gap-x-5">
                <button
                  className="rounded-md bg-black px-3 py-2 text-sm text-white hover:scale-105 hover:opacity-80"
                  onClick={() => navigate("/dashboard/profile")}
                >
                  Cancel
                </button>
                <button
                  className="rounded-md border border-stone-200 bg-white px-3 py-2 text-sm text-black hover:scale-105 hover:opacity-90"
                  onClick={handleAccountDelete}
                >
                  Delete my account
                </button>
              </div>
            </>
          ) : (
            <>
              <p className="m-auto max-w-80">
                Please enter the 6-digit code below to confirm the deletion of
                your account. Please be aware that deleting your account is
                irreversible. Once deleted, your account cannot be restored.
              </p>
              <div className="my-5 flex justify-center space-x-2">
                {inputs.map((input, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={input}
                    onChange={(e) => handleChange(e.target.value, index)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    onPaste={(e) => handlePaste(index, e)}
                    onKeyPress={handleKeyPress}
                    ref={(el) => {
                      inputRefs.current[index] = el;
                    }}
                    className="form-control mb-4 h-8 w-8 rounded text-center lg:mb-2 lg:h-10 lg:w-10"
                    style={{ fontSize: "16px", fontWeight: "bold" }}
                  />
                ))}
              </div>
              <div className="flex items-center justify-center gap-x-5">
                <button
                  className="rounded-md bg-black px-3 py-2 text-sm text-white hover:scale-105 hover:opacity-80"
                  onClick={() => navigate("/dashboard/profile")}
                >
                  Cancel
                </button>
                <button
                  className="rounded-md border border-stone-200 bg-white px-3 py-2 text-sm text-black hover:scale-105 hover:opacity-90"
                  onClick={handleConfirmDelete}
                >
                  Confirm delete
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default DeleteAccount;
