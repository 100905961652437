import Faq from "../components/Faq";
import FooterSection from "../components/Footer";
import Navbar from "../components/Navbar";
import Plans from "../components/Plans";

const PlansAndFaqPage = () => {
  return (
    <section className="min-h-screen bg-black">
      <Navbar />
      <Plans />
      <Faq />
      <FooterSection />
    </section>
  );
};

export default PlansAndFaqPage;
