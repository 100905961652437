import { useEffect, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  faArrowLeftLong,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import PhoneInput from "react-phone-input-2";
import { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import "../../styles/SignUp.css";
import countriesData from "../../assets/countriesData.json";
import { LoginContext } from "../Context/LoginContext/LoginContext";
import LazyLoadImages from "../LazyLoadImages";
import axios from "axios";

interface CountryInfo {
  country: string;
  nationality: string;
}
interface SignUpEmailProps {
  onGoBack: () => void;
  displayInfoMessage: (message: string) => void;
}

const { REACT_APP_API_URL, REACT_APP_S3_URL } = process.env;

const SignUpEmail: React.FC<SignUpEmailProps> = ({
  onGoBack,
  displayInfoMessage,
}) => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const fromValue = urlParams.get("from");
  const campaignId = urlParams.get("gameId");
  const contextValue = useContext(LoginContext);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [nationality, setNationality] = useState("");
  const [phoneNumberWithoutDialCode, setPhoneNumberWithoutDialCode] =
    useState("");

  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    phone: "",
    email: "",
    password: "",
    isdCode: "",
    agreeToTerms: false,
  });

  // Name validation
  const handleFirstChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const alphaRegex = /^[a-zA-Z\s]*$/;

    if (name === "fname") {
      if (!value.trim()) {
        toast.error("Please enter your first name", {
          autoClose: 2000,
          className: "toastify-toast",
        });
      } else if (!alphaRegex.test(value)) {
        toast.error("Name should contain only alphabets", {
          autoClose: 2000,
          className: "toastify-toast",
        });
        return;
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLastChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const alphaRegex = /^[a-zA-Z\s]*$/;

    if (name === "lname") {
      if (!value.trim()) {
        toast.error("Please enter your last name", {
          autoClose: 2000,
          className: "toastify-toast",
        });
      } else if (!alphaRegex.test(value)) {
        toast.error("Name should contain only alphabets", {
          autoClose: 2000,
          className: "toastify-toast",
        });
        return;
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // PhoneNumber validation
  const handlePhoneChange = (value: string, country: CountryData) => {
    // Find the country info based on the country name
    const countryInfo: CountryInfo | undefined = countriesData.find(
      (info: CountryInfo) => info.country === country.name,
    );

    if (!countryInfo) {
      console.error(`Country info not found for name: ${country.name}`);
      return;
    }

    setNationality(countryInfo.nationality);
    setFormData({
      ...formData,
      isdCode: country.dialCode,
    });

    const dialCode = country.dialCode;

    const phoneNumberWithoutDialCodeValue = value.replace(dialCode, "");
    setPhoneNumberWithoutDialCode(phoneNumberWithoutDialCodeValue);
    setFormData((prevState) => ({
      ...prevState,
      phone: value,
    }));
  };

  // Email
  const handleEmailChange = (email: string) => {
    setFormData({
      ...formData,
      email: email,
    });
  };

  // Password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // Password validation
  const handlePasswordChange = (password: string) => {
    // Set form data
    setFormData({
      ...formData,
      password: password,
    });

    // Validation regular expressions
    const digitRegex = /\d/;
    const uppercaseRegex = /[A-Z]/;
    const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

    // Check if the entered password meets all constraints
    const isPasswordValid =
      password.length >= 8 &&
      digitRegex.test(password) &&
      uppercaseRegex.test(password) &&
      specialCharRegex.test(password);

    // Set password validation flag
    setPasswordValid(isPasswordValid);

    // Set password error message if password is present but doesn't meet criteria
    if (password && !isPasswordValid) {
      setPasswordErrorMessage(
        "Password must be at least 8 characters long, contain at least 1 digit, 1 uppercase letter, and 1 special character",
      );
    } else if (!password) {
      // If password field is empty, display "Please enter a valid password" error
      setPasswordErrorMessage("Please enter a valid password");
    } else {
      // Clear the error message if password is valid
      setPasswordErrorMessage("");
    }
  };

  // Checkbox for agree to terms and conditions
  const handleCheckboxChange = () => {
    const agreeToTerms = !formData.agreeToTerms;

    // Update form data
    setFormData({
      ...formData,
      agreeToTerms: agreeToTerms,
    });

    if (!agreeToTerms) {
      toast.error("Please agree to the Terms and Conditions", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    }
  };
  const redirectToGame = (token: any) => {
    if (campaignId && fromValue) {
      navigate(
        `/dashboard/campaigns/create?gameId=${encodeURIComponent(campaignId)}&fromValue=${fromValue}`,
      );
    }
  };

  const handleSubmit = async () => {
    let isError = false;
    // Check if any of the required fields are empty
    if (
      !formData.fname &&
      !formData.lname &&
      !formData.email &&
      !formData.password &&
      !formData.agreeToTerms
    ) {
      toast.error("Please fill in all the fields", {
        autoClose: 2000,
        className: "toastify-toast",
      });
      isError = true;

      return;
    } else {
      if (!formData.fname) {
        toast.error("Please enter your first name", {
          autoClose: 2000,
          className: "toastify-toast",
        });
        return;
      }
      if (!formData.lname) {
        toast.error("Please enter your last name", {
          autoClose: 2000,
          className: "toastify-toast",
        });
        return;
      }

      if (formData.phone) {
        if (
          nationality === "Indian" &&
          phoneNumberWithoutDialCode.replace(/\D/g, "").length !== 10
        ) {
          toast.error("Please enter a valid phone number", {
            autoClose: 2000,
            className: "toastify-toast",
          });
          return;
        }
      }

      if (!formData.email) {
        toast.error("Please enter your email address", {
          autoClose: 2000,
          className: "toastify-toast",
        });
        return;
      }

      if (formData.email) {
        if (!/\S+@\S+\.\S+/.test(formData.email)) {
          toast.error("Please enter a valid email address", {
            autoClose: 2000,
            className: "toastify-toast",
          });
          return;
        }
      }

      if (!formData.password.trim()) {
        toast.error("Please enter your password", {
          autoClose: 2000,
          className: "toastify-toast",
        });
        return;
      }

      // Check if the password is empty or doesn't meet criteria
      if (!passwordValid) {
        toast.error(
          "Password must be at least 8 characters long, contain at least 1 digit, 1 uppercase letter, and 1 special character",
          {
            autoClose: 3000,
            className: "toastify-toast",
          },
        );
        return;
      }

      if (!formData.agreeToTerms) {
        toast.error("Please agree to the Terms and Conditions", {
          autoClose: 2000,
          className: "toastify-toast",
        });
        isError = true;
      }
    }

    setFormSubmitted(true);
    const URL = `${REACT_APP_API_URL}/auth/signup`;

    if (!isError) {
      if (!URL) {
        return;
      }
      try {
        const response = await axios.post(
          URL,
          {
            firstName: formData.fname,
            lastName: formData.lname,
            email: formData.email,
            ...(formData.isdCode && { isdCode: "+" + formData.isdCode }),
            ...(phoneNumberWithoutDialCode && {
              phoneNumber: phoneNumberWithoutDialCode,
            }),
            password: formData.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );

        if (response.data.success === true) {
          contextValue?.login();

          localStorage.setItem("xSudTzMYmd", response.data.data.accessToken);
          if (formSubmitted) {
            setFormData({
              fname: "",
              lname: "",
              phone: "",
              email: "",
              password: "",
              isdCode: "",
              agreeToTerms: false,
            });
          }

          if (campaignId && fromValue) {
            redirectToGame(response.data.data.accessToken);
          } else {
            setTimeout(() => {
              navigate("/dashboard/campaigns", { replace: true });
            }, 1000);
          }
        }
      } catch (error: any) {
        toast.error(error.response.data.message, {
          autoClose: 2000,
          className: "toastify-toast",
        });
        contextValue?.logout();
        console.error("Error creating user:", error);
      }
    }
  };

  const handleBackButtonClick = () => {
    if (onGoBack) {
      onGoBack();
    }
  };

  const handleLogoRedirect = () => {
    navigate("/");
  };

  useEffect(() => {
    if (localStorage.getItem("xSudTzMYmd")) {
      const token = localStorage.getItem("xSudTzMYmd");
      if (fromValue && campaignId) {
        redirectToGame(token);
      } else navigate("/", { replace: true });
    }
  }, []);

  return (
    <>
      <div
        style={{
          pointerEvents: localStorage.getItem("xSudTzMYmd") ? "none" : "auto",
        }}
      >
        <div className="min-h-screen items-center lg:flex">
          <div className="lg:h-screen lg:w-[50%] lg:bg-gray-100">
            <LazyLoadImages
              className="m-7 h-[25px] cursor-pointer xsm:h-6 xl:mx-10"
              src={`${REACT_APP_S3_URL}/assets/workplay-logo.webp`}
              alt="workplay-logo"
              onClick={handleLogoRedirect}
            />
            <div className="flex items-center justify-center lg:mt-20">
              <LazyLoadImages
                className="h-[200px] xsm:h-[260px] sm:h-[320px] xl:h-[450px]"
                src={`${REACT_APP_S3_URL}/assets/sign-up.webp`}
                alt="sign-up"
              />
            </div>
          </div>
          <div className="m-auto flex items-center justify-center py-6 lg:w-[50%] lg:py-0">
            <div className="xxsm:w-[320px] w-[300px] xsm:w-[360px] sm:w-[400px] lg:w-[384px]">
              <form
                id="form"
                className="mx-auto flex flex-col font-semibold text-black"
              >
                <div
                  className="mb-4 hidden cursor-pointer items-center justify-start sm:flex"
                  onClick={handleBackButtonClick}
                >
                  <FontAwesomeIcon icon={faArrowLeftLong} />
                  <p className="ml-2">back</p>
                </div>
                <div className="flex items-center text-black">
                  <h2 className="m-auto text-center text-2xl font-bold text-black lg:ml-0 lg:text-left lg:text-3xl">
                    <strong className="relative before:bg-[#e9e7e7]">
                      Create
                    </strong>{" "}
                    an account
                  </h2>
                </div>{" "}
                <p className="mb-6 mt-3 text-center text-[17px] font-medium sm:mt-4 lg:text-left">
                  Get Started with Workplay!
                </p>
                <div className="hidden text-left sm:flex">
                  <label className="font-medium" htmlFor="name">
                    Name
                  </label>
                  <span className=" text-red-600">&nbsp;*</span>
                </div>
                <div className="block text-left sm:hidden">
                  <label className="font-medium" htmlFor="name">
                    First Name
                  </label>
                  <span className=" text-red-600">&nbsp;*</span>
                </div>
                <div className="items-center justify-between gap-x-1 xsm:w-[360px] sm:flex sm:w-[400px] lg:w-[384px]">
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    placeholder="Firstname"
                    className="xxsm:w-[320px] mb-2 mt-1 h-[44px] w-[300px] rounded-md bg-white px-3 text-base font-normal text-black xsm:w-[360px] sm:w-[196px] lg:w-[188px]"
                    value={formData.fname}
                    onChange={handleFirstChange}
                    required
                  />
                  <div className="block text-left sm:hidden">
                    <label className="font-medium" htmlFor="name">
                      Last Name
                    </label>
                    <span className=" text-red-600">&nbsp;*</span>
                  </div>
                  <input
                    type="text"
                    className="xxsm:w-[320px] mb-2 mt-1 h-[44px] w-[300px] rounded-md bg-white px-3 text-base font-normal text-black xsm:w-[360px] sm:w-[196px] lg:w-[188px]"
                    id="lname"
                    name="lname"
                    placeholder="Lastname"
                    value={formData.lname}
                    onChange={handleLastChange}
                    required
                  />
                </div>
                <label className="mt-1 font-medium text-black" htmlFor="phone">
                  Phone Number
                </label>
                <PhoneInput
                  country={"in"}
                  value={formData.phone}
                  onChange={handlePhoneChange}
                />
                <div className="flex text-left">
                  <label className="mt-1 font-medium" htmlFor="email">
                    Email
                  </label>
                  <span className=" text-red-600">&nbsp;*</span>
                </div>
                <input
                  type="email"
                  id="email"
                  className="xxsm:w-[320px] m-auto my-1 h-[44px] w-[300px] rounded-md bg-white px-3 text-base font-normal text-black xsm:w-[360px] sm:w-[400px] lg:w-[384px]"
                  name="email"
                  value={formData.email}
                  onChange={(e) => handleEmailChange(e.target.value)}
                  placeholder="email@address.com"
                  required
                />
                <div className="flex text-left">
                  <label className="mt-1 font-medium" htmlFor="password">
                    Password
                  </label>
                  <span className=" text-red-600">&nbsp;*</span>
                </div>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    className="xxsm:w-[320px] m-auto my-1 h-[44px] w-[300px] rounded-md bg-white px-3 text-base font-normal text-black xsm:w-[360px] sm:w-[400px] lg:w-[384px]"
                    value={formData.password}
                    onChange={(e) => handlePasswordChange(e.target.value)}
                    placeholder="Must have at least 8 characters"
                    required
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="absolute right-4 top-1/2 h-4 -translate-y-1/2 transform cursor-pointer text-black"
                    onClick={togglePasswordVisibility}
                  />
                </div>
                {formSubmitted && passwordErrorMessage && (
                  <p className="pb-1 font-normal text-red-500">
                    {passwordErrorMessage}
                  </p>
                )}
                <div className="flex items-center text-[15px]">
                  <label
                    htmlFor="agreeToTerms"
                    className="mb-4 mt-2  font-medium text-black"
                  >
                    <input
                      type="checkbox"
                      id="agreeToTerms"
                      name="agreeToTerms"
                      checked={formData.agreeToTerms}
                      onChange={handleCheckboxChange}
                      className="mr-2 h-4 w-4 cursor-pointer border-2 border-black"
                    />
                    Agree to our
                    <a
                      href="/privacy-policy"
                      className="ml-1.5 font-semibold text-black underline"
                    >
                      Privacy Policy & conditions
                    </a>
                  </label>
                </div>
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="xxsm:w-[320px] flex h-[44px] w-[300px] items-center justify-center rounded-md border border-solid border-black bg-black text-base font-medium text-white hover:scale-1.2 xsm:w-[360px] sm:w-[400px] lg:w-[384px]"
                >
                  Create account
                </button>
              </form>
              <p className="mt-4 text-center font-medium text-black">
                Already have an account?
                <Link
                  to={
                    fromValue && campaignId
                      ? `/login?from=${fromValue}&gameId=${campaignId}`
                      : "/login"
                  }
                  className="ml-1 text-black underline hover:opacity-60"
                >
                  <strong className="relative text-base font-semibold before:bg-[#e9e7e7]">
                    Sign In
                  </strong>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default SignUpEmail;
