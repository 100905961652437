import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import LazyLoadImages from "./LazyLoadImages";
import "../styles/Sections/Footer.css";

const { REACT_APP_S3_URL } = process.env;

interface FooterProps {
  iconName: string;
  iconDetail?: string;
}

const Footer: React.FC<FooterProps> = ({ iconName, iconDetail }) => {
  let icon: IconProp;

  switch (iconName) {
    case "linkedin":
      icon = faLinkedin as IconProp;
      break;
    case "facebook":
      icon = faFacebook as IconProp;
      break;
    case "instagram":
      icon = faInstagram as IconProp;
      break;
    case "phoneVolume":
      icon = faPhoneVolume as IconProp;
      break;
    default:
      icon = faEnvelope as IconProp;
  }

  return (
    <div className="mb-1 flex items-center xsm:mb-2">
      <div className="mr-3 text-2xl text-white sm:mr-5 sm:text-2xl lg:text-3xl">
        <FontAwesomeIcon icon={icon} />
      </div>
      <div>
        <p className="text-white">{iconDetail}</p>
      </div>
    </div>
  );
};

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const FooterSection: React.FC = () => {
  return (
    <section
      id="footer"
      className="m-auto flex w-auto items-center bg-[#1e1e1e] p-8 text-center text-base font-medium text-white max-[360px]:text-[14px] lg:px-16 lg:py-5 lg:text-[17px] xl:px-32 3xl:px-72 3xl:py-10"
    >
      <div className="lg:m-auto lg:w-[40%]">
        <LazyLoadImages
          className="mb-5 h-6 cursor-pointer lg:mb-10 lg:h-7"
          onClick={scrollToTop}
          src={`${REACT_APP_S3_URL}/assets/wp-logo.webp`}
          alt="workplay-logo"
        />
        <div className="socials my-5 flex items-center">
          <Link
            className="cursor-pointer no-underline opacity-90 hover:opacity-70"
            to="https://www.linkedin.com/company/workplaystudio/"
            target="_blank"
          >
            <Footer iconName="linkedin" />
          </Link>
          <Link
            className="cursor-pointer no-underline opacity-90 hover:opacity-70"
            to="https://www.facebook.com/people/WorkPlay-Studio/100075423147764/"
            target="_blank"
          >
            <Footer iconName="facebook" />
          </Link>
          <Link
            className="cursor-pointer no-underline opacity-90 hover:opacity-70"
            to="https://www.instagram.com/workplaystudio/"
            target="_blank"
          >
            <Footer iconName="instagram" />
          </Link>
        </div>
        <Link
          className="no-underline"
          to="mailto:info@workplay.digital"
          target="_blank"
        >
          <Footer
            iconName="envelope"
            iconDetail=": &nbsp;&nbsp; info@workplay.digital"
          />
        </Link>
        <Footer
          iconName="phoneVolume"
          iconDetail=": &nbsp;&nbsp;+91 97428 47450"
        />
      </div>
      <span className="mx-auto my-6 block h-0 rounded-full border border-[#64646493] p-0 xsm:my-8 lg:mx-20 lg:h-64 lg:border-2"></span>
      <div className="relative flex justify-between lg:w-[60%]">
        <ul className="text-left">
          <li className="mb-2 list-none lg:mb-4">
            <Link
              className="no-underline opacity-90 hover:opacity-70"
              to="/"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Home
            </Link>
          </li>
          <li className="mb-2 list-none lg:mb-4">
            <HashLink
              className="no-underline opacity-90 hover:opacity-70"
              to="/#about"
            >
              About Us
            </HashLink>
          </li>
          <li className="mb-2 list-none lg:mb-4">
            <HashLink
              className="no-underline opacity-90 hover:opacity-70"
              to="/#what-we-do"
            >
              What we do
            </HashLink>
          </li>
          <li className="mb-2 list-none lg:mb-4">
            <HashLink
              className="no-underline opacity-90 hover:opacity-70"
              to="/#our-services"
            >
              Our Services
            </HashLink>
          </li>
          <li className="list-none">
            <Link
              className="no-underline opacity-90 hover:opacity-70"
              to="/contact"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Contact
            </Link>
          </li>
        </ul>
        <ul className="absolute bottom-0 right-0 text-right font-bold opacity-75 max-[400px]:text-[14px]">
          {" "}
          <li className="mb-2 max-[400px]:mb-1">
            <Link
              className="no-underline opacity-90 hover:opacity-70"
              to="/privacy-policy"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              PRIVACY POLICY
            </Link>
          </li>
          <li className="hidden sm:block">
            © 2024 WorkPlay Studio Private Limited
          </li>
          <li className="mb-2 sm:hidden">© 2024 WorkPlay Studio</li>
          <li className="sm:hidden">Private Limited</li>
        </ul>
      </div>
    </section>
  );
};

export default FooterSection;
